
import React from "react";
import PropTypes from "prop-types";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import { wrap as wrapDialog, DialogPropType } from "@/components/DialogWrapper";
import { axios } from "@/services/axios";
import message from "antd/lib/message";



class EditWidgetNameDialog extends React.Component {
    static propTypes = {
        dashboard: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        widget: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        dialog: DialogPropType.isRequired,
        name: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            name: props.name,
            updateInProgress: false,
        };

    }

    saveName() {
        const valuesChanged = this.state.name !== this.props.name;
        if (!valuesChanged) return this.props.dialog.close(valuesChanged);
        axios.post(`api/widgets/update_name/${this.props.widget?.id}`, { widgetName: this.state.name }).then(data => {
            message.success('修改成功')
            this.props.widget.options.widgetName = this.state.name;
            this.props.dialog.close(valuesChanged);
        });
    }

    updateName(name) {
        this.setState({ name });
    }

    render() {
        const { dialog } = this.props;
        return (
            <Modal
                {...dialog.props}
                title="Edit Name"
                onOk={() => this.saveName()}
                okButtonProps={{ loading: this.state.updateInProgress }}
                width={700}>
                <Input defaultValue={this.state.name} onChange={e => {
                    this.updateName(e.target?.value)
                }} />
            </Modal>
        );
    }
}

export default wrapDialog(EditWidgetNameDialog);
