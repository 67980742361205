import React, { useState, useImperativeHandle } from "react";
import Modal from "antd/lib/modal/Modal";
import notification from "@/services/notification";
import Icon from "@/components/Icon";
import { Dashboard } from "@/services/dashboard";

export default ({ modalRef, dashboardId }) => {
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const show = id => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  const onOk = async () => {
    setLoading(true);
    notification.config({ placement: "topRight" });
    try {
      const data = await Dashboard.syncDashboard(dashboardId);
      notification.success(data.msg);
      hide();
    } catch {
      notification.success("同步失败，请稍后再试！");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={hide}
      keyboard={false}
      maskClosable={false}
      className="sync-confirm-modal"
      okButtonProps={{
        loading: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}>
      <Icon type="icon-cion56" />
      确认同步此dashboard至正式环境吗？
    </Modal>
  );
};
