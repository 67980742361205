import React, { useEffect, useState } from "react";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";

export default function NumberField({ form, field, value, onChange, ...otherProps }) {
  const [innerValue, setInnerValue] = useState();

  useEffect(() => {
    if (value) {
      setInnerValue(moment(value));
    } else {
      setInnerValue(undefined);
    }
  }, [value]);

  const handleChange = (date, dateString) => {
    setInnerValue(date);
    onChange(dateString);
  };

  return <DatePicker value={innerValue} onChange={handleChange} {...otherProps} />;
}
