import React, { useState, useEffect, useCallback } from "react";
import { axios } from "@/services/axios";
import PropTypes from "prop-types";
import Button from "antd/lib/button";
import List from "antd/lib/list";
import Modal from "antd/lib/modal";
import Tag from "antd/lib/tag";
import { wrap as wrapDialog, DialogPropType } from "@/components/DialogWrapper";
import { UserPreviewCard } from "@/components/PreviewCard";
import notification from "@/services/notification";


import "./index.less";


function useGrantees(url) {
  const loadGrantees = useCallback(
    () =>
      axios.get(url).then(data => data),
    [url]
  );

  const addPermission = useCallback(
    (userId, accessType = "modify") =>
      axios
        .post(url, { access_type: accessType, user_id: userId })
        .catch(() => notification.error("Could not grant permission to the user")),
    [url]
  );



  return { loadGrantees, addPermission };
}


function ViewPermissionsEditorHeader({ context }) {
  return (
    <>
      View Permissions
      {/* <div className="modal-header-desc">
        {`Editing this ${context} is enabled for the users in this list and for admins. `}
        <HelpTrigger type="MANAGE_PERMISSIONS" />
      </div> */}
    </>
  );
}

ViewPermissionsEditorHeader.propTypes = { context: PropTypes.oneOf(["query", "dashboard"]) };
ViewPermissionsEditorHeader.defaultProps = { context: "query" };



function ViewPermissionsEditor({ dialog, author, context, aclUrl }) {
  const [loadingGrantees, setLoadingGrantees] = useState(true);

  const [grantees, setGrantees] = useState([]);

  const [count, setCount] = useState();

  const { loadGrantees } = useGrantees(aclUrl);

  const loadUsersWithPermissions = useCallback(() => {
    setLoadingGrantees(true);
    loadGrantees()
      .then((result) => {
        setGrantees(result.view)
        setCount(result.widget_count)
      })
      .catch(() => notification.error("Failed to load grantees list"))
      .finally(() => setLoadingGrantees(false));
  }, [loadGrantees]);



  useEffect(() => {
    loadUsersWithPermissions();
  }, [aclUrl, loadUsersWithPermissions]);


  return (
    <Modal
      {...dialog.props}
      className="permissions-editor-dialog"
      title={<ViewPermissionsEditorHeader context={context} />}
      footer={<Button onClick={dialog.dismiss}>Close</Button>}>
      <div className="d-flex align-items-center m-t-5">
        <h5 className="flex-fill">总数:{count || '-'}</h5>
        {loadingGrantees && (
          <span role="status" aria-live="polite" aria-relevant="additions removals">
            <i className="fa fa-spinner fa-pulse" aria-hidden="true" />
            <span className="sr-only">Loading...</span>
          </span>
        )}
      </div>
      <div className="scrollbox p-5" style={{ maxHeight: "40vh" }}>

        <div >
          <h5 className="flex-fill">Bigone</h5>
          <List
            size="small"
            dataSource={grantees.filter((i) => i.group_type === 'internal')}
            renderItem={user => (
              <List.Item>
                <UserPreviewCard key={user.id} user={user}>
                  <div className="tag-wrapper">
                    {
                      user.groups.map((item) => {
                        return <Tag key={item.id}>{item.name}</Tag>
                      })
                    }
                  </div>

                </UserPreviewCard>
              </List.Item>
            )}
          />
        </div>

        <div >
          <h5 className="flex-fill">客户</h5>
          <List
            size="small"
            dataSource={grantees.filter((i) => i.group_type === 'client')}
            renderItem={user => (
              <List.Item>
                <UserPreviewCard key={user.id} user={user}>
                  <div className="tag-wrapper">
                    {
                      user.groups.map((item) => {
                        return <Tag key={item.id}>{item.name}</Tag>
                      })
                    }
                  </div>

                </UserPreviewCard>
              </List.Item>
            )}
          />
        </div>

      </div>
    </Modal>
  );
}

ViewPermissionsEditor.propTypes = {
  dialog: DialogPropType.isRequired,
  author: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  context: PropTypes.oneOf(["query", "dashboard"]),
  aclUrl: PropTypes.string.isRequired,
};

ViewPermissionsEditor.defaultProps = { context: "query" };

export default wrapDialog(ViewPermissionsEditor);
