import React, { useState, useImperativeHandle } from "react";
import Modal from "antd/lib/modal";
import notification from "@/services/notification";
import Group from "@/services/group";
import { flatMap } from "lodash";
import { permissionTestTree, permissionProdTree } from "./constant";
import Tree from "antd/lib/tree";
import "antd/lib/tree/style/css";
import "./index.less";

const resultTree = process.env.REDASH_ENV === "prod" ? permissionProdTree : permissionTestTree;

export default function EditPermission({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [value, setValue] = useState([]);

  const [itemDetail, setItemDetail] = useState({});

  const [loading, setLoading] = useState(false);

  const onChange = v => {
    setValue(v);
  };

  const show = item => {
    // 获取基本信息
    setItemDetail(item);
    setVisible(true);
    setValue(item.permissions);
  };

  const hide = () => {
    setVisible(false);
    setItemDetail({});
    setValue([]);
  };

  const onSuccess = async () => {
    setLoading(true);
    notification.config({ placement: "topRight" });
    const temp = flatMap(resultTree.map(item => item.children)).map(item => item.key);
    Group.save({
      ...(itemDetail || {}),
      permissions: value.filter(item => temp.includes(item)) || [],
    })
      .then(() => {
        notification.success("修改成功");
        hide();
        onSuccessChange();
        setLoading(false);
      })
      .catch(e => {
        notification.error("修改失败，稍后再试");
        setLoading(false);
      });
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Modal
      visible={visible}
      title={`功能权限 | ${itemDetail?.name}`}
      onCancel={hide}
      okButtonProps={{ loading }}
      className="permissionEditModal"
      onOk={onSuccess}
      footer={itemDetail.type === 'builtin' ? <></> : undefined}
      destroyOnClose>
      <Tree defaultExpandAll onCheck={onChange} checkedKeys={value} treeData={resultTree} checkable={true} disabled={itemDetail.type === 'builtin'} />
    </Modal>
  );
}
