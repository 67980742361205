import { isNil, isUndefined, isFunction, isObject, trimStart, mapValues, omitBy, extend } from "lodash";
import qs from "query-string";
import { createBrowserHistory } from "history";
import { axios } from "@/services/axios";
import { isEmpty, map } from 'lodash'

const history = createBrowserHistory();

function normalizeLocation(rawLocation) {
  const { pathname, search, hash } = rawLocation;
  const result = {};

  result.path = pathname;
  result.search = mapValues(qs.parse(search), value => (isNil(value) ? true : value));
  result.hash = trimStart(hash, "#");
  result.url = `${pathname}${search}${hash}`;

  return result;
}

const location = {
  // dashboards和page页面记录查询入参
  recordSearch(allSearch) {
    const pathArr = location?.path?.split('/')
    const page = pathArr[1];
    let type_id = ''
    if (pathArr[2]) {
      const idArr = pathArr[2]?.split('--')
      type_id = idArr[0]
    }
    if (['dashboards', 'queries'].includes(page) && !isEmpty(type_id)) {
      const data = {
        type: page === 'dashboards' ? 1 : 2,
        type_id,
        data: map(allSearch, (value, key) => {
          return { key, value }
        })
      }
      axios.post('api/queries_content', data)
    }
  },
  listen(handler) {
    if (isFunction(handler)) {
      return history.listen((unused, action) => handler(location, action));
    } else {
      return () => { };
    }
  },

  confirmChange(handler) {
    if (isFunction(handler)) {
      return history.block(nextLocation => {
        return handler(normalizeLocation(nextLocation), location);
      });
    } else {
      return () => { };
    }
  },

  update(newLocation, replace = false) {
    let allSearch = {};
    if (isObject(newLocation)) {
      // remap fields and remove undefined ones
      newLocation = omitBy(
        {
          pathname: newLocation.path,
          search: newLocation.search,
          hash: newLocation.hash,
        },
        isUndefined
      );

      // keep existing fields (!)
      newLocation = extend(
        {
          pathname: location.path,
          search: location.search,
          hash: location.hash,
        },
        newLocation
      );


      // serialize search and keep existing search parameters (!)
      if (isObject(newLocation.search)) {
        allSearch = omitBy(extend({}, location.search, newLocation.search), isNil);
        newLocation.search = mapValues(allSearch, value => (value === true ? null : value));
        newLocation.search = qs.stringify(newLocation.search);
      }

    }
    try {
      location.recordSearch(allSearch);
    } catch (error) {
      console.error(error)
    }
    if (replace) {
      history.replace(newLocation);
    } else {
      history.push(newLocation);
    }
  },

  url: undefined,

  path: undefined,
  setPath(path, replace = false) {
    location.update({ path }, replace);
  },

  search: undefined,
  setSearch(search, replace = false) {
    location.update({ search }, replace);
  },

  hash: undefined,
  setHash(hash, replace = false) {
    location.update({ hash }, replace);
  },
};

function locationChanged() {
  extend(location, normalizeLocation(history.location));
}

history.listen(locationChanged);
locationChanged(); // init service

export default location;
