import React, { useState, useImperativeHandle } from "react";
import Collapse from "antd/lib/collapse";
import Drawer from "antd/lib/drawer";
import { PermissionOption } from "@/components/groups/EditPermissions/constant";

export default function EditPermission({ drawerRef }) {
  const [visible, setVisible] = useState(false);

  const [itemDetail, setItemDetail] = useState({});

  const show = async item => {
    setItemDetail(item);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    setItemDetail({});
  };

  if (drawerRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(drawerRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Drawer
      visible={visible}
      title={`${itemDetail?.name} Detail`}
      width={400}
      onClose={hide}
      className="permissionEditModal"
      destroyOnClose>
      <Collapse defaultActiveKey={["permission", "dashboard"]}>
        <Collapse.Panel header="Permission" key="permission">
          {itemDetail?.permissions?.map(item => (
            <div key={item}>{PermissionOption.find(i => i.value === item)?.label || item}</div>
          ))}
        </Collapse.Panel>
        {itemDetail?.type !== "builtin" && (
          <Collapse.Panel header="Dashboard" key="dashboard">
            {itemDetail?.dashboards?.map(item => (
              <div key={item.id}>{item.name}</div>
            ))}
          </Collapse.Panel>
        )}
      </Collapse>
    </Drawer>
  );
}
