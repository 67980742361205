import React from "react";
import PropTypes from "prop-types";
import PlainButton from "@/components/PlainButton";

export default class CopyControl extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      is_favorite: PropTypes.bool.isRequired,
    }).isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  toggleItem(event, item, callback) {
    const action = item.copy.bind(item);
    action().then(() => {
      window.location.reload();
      // this.forceUpdate();
      // callback();
    });
  }

  render() {
    const { item, onChange } = this.props;
    const icon = "fa-thin fa-copy";
    const title = "Copy the queries";
    return (
      <PlainButton
        title={title}
        aria-label={title}
        className="copy-control btn-favorite"
        onClick={event => this.toggleItem(event, item, onChange)}>
        <i className={icon} aria-hidden="true" />
      </PlainButton>
    );
  }
}
