import React from "react";
import { VisualizationType } from "@redash/viz/lib";

import "./VisualizationName.less";

function VisualizationName({ visualization }) {
  return <span className="visualization-name">{visualization.name}</span>;
}

VisualizationName.propTypes = {
  visualization: VisualizationType.isRequired,
};

export default VisualizationName;
