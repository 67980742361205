import React, { useState, useImperativeHandle, useEffect } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import DataSource from "@/services/data-source";
import { Query } from "@/services/query";
import notification from "@/services/notification";

export default function QueryCopyModal({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const [selectoption, setSelectOption] = useState([]);

  const [selectLoading, setSelectLoading] = useState(false);

  useEffect(() => {
    setSelectLoading(true);
    DataSource.query()
      .then(res => {
        setSelectOption(
          res.map(item => ({
            label: `${item.name} (${item.type})`,
            value: item.id,
          }))
        );
        setSelectLoading(false);
      })
      .catch(() => {
        setSelectOption([]);
        setSelectLoading(false);
      });
  }, []);

  const show = item => {
    // 获取基本信息
    setVisible(true);
    const option = {
      query_id: item.id,
      query_name: `Copy of (#${item.id}) ${item.name}`,
    };
    if (!selectLoading && selectoption.some(item => item.value === item.data_source_id)) {
      form.setFieldsValue({
        datasource_id: item.data_source_id,
        ...option,
      });
    } else {
      form.setFieldsValue(option);
    }
  };

  const hide = () => {
    setVisible(false);
    form.resetFields();
    setSelectLoading(false);
    setLoading(false);
  };

  const onSuccess = async () => {
    notification.config({ placement: "topRight" });
    const formData = await form.validateFields();
    setLoading(true);
    try {
      await Query.copyQuery(formData);
      notification.success("复制成功");
      hide();
      onSuccessChange();
    } catch {
      notification.error("复制失败 请稍后重试!");
    } finally {
      setLoading(false);
    }
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Modal
      visible={visible}
      title="复制 Query"
      onCancel={hide}
      maskClosable={false}
      okButtonProps={{ loading }}
      onOk={onSuccess}>
      <Form form={form}>
        <Form.Item name="query_id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="query_name"
          label="Copy Query Name"
          rules={[{ required: true, message: "请输入复制后Query名字" }]}>
          <Input placeholder="输入Query Name" />
        </Form.Item>
        <Form.Item name="datasource_id" label="Datasource" rules={[{ required: true, message: "请选择数据源" }]}>
          <Select placeholder="选择数据源" options={selectoption} loading={selectLoading} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
