export const PermissionOption = [{
  label: "Dashboard列表",
  value: "list_dashboards"
}, {
  label: "创建Dashboard",
  value: "create_dashboard"
}, {
  label: "编辑Dashboard",
  value: "edit_dashboard"
},

{
  label: "Query列表",
  value: "list_query"
}, {
  label: "创建Query",
  value: "create_query"
}, {
  label: "编辑Query",
  value: "edit_query"
}, {
  label: "仅查看Query结果集",
  value: "view_query"
}, {
  label: "仅查看Query Schema",
  value: "view_source"
}, {
  label: "运行Query",
  value: "execute_query"
}, {
  label: "定时刷新Query",
  value: "schedule_query"
},

{
  label: "Alert列表",
  value: "list_alerts"
},
{
  label: "创建Alert",
  value: "create_alerts"
},

{
  label: "DataSource列表",
  value: "list_data_sources"
}, {
  label: "User列表",
  value: "list_users"
}, {
  label: "Group列表",
  value: "list_groups"
}, {
  label: "Destinations列表",
  value: "list_destinations"
}, {
  label: "QuerySnippets列表",
  value: "list_query_snippets"
}];

export const permissionTestTree = [{
  title: "Dashboard",
  key: "Dashboard",
  children: [
    {
      title: "Dashboard列表",
      key: "list_dashboards"
    }, {
      title: "创建Dashboard",
      key: "create_dashboard"
    }, {
      title: "编辑Dashboard",
      key: "edit_dashboard"
    },
  ]
},
{
  title: "Query",
  key: "Query",
  children: [
    {
      title: "Query列表",
      key: "list_query"
    }, {
      title: "创建Query",
      key: "create_query"
    }, {
      title: "编辑Query",
      key: "edit_query"
    }, {
      title: "仅查看Query结果集",
      key: "view_query"
    }, {
      title: "仅查看Query Schema",
      key: "view_source"
    }, {
      title: "运行Query",
      key: "execute_query"
    }, {
      title: "定时刷新Query",
      key: "schedule_query"
    },
  ]
}, {
  title: "Alert",
  key: "Alert",
  children: [
    {
      title: "Alert列表",
      key: "list_alerts"
    },
    {
      title: "创建Alert",
      key: "create_alerts"
    },
  ]
}, {
  title: "Setting",
  key: "Setting",
  children: [
    {
      title: "DataSource列表",
      key: "list_data_sources"
    }, {
      title: "User列表",
      key: "list_users"
    }, {
      title: "Group列表",
      key: "list_groups"
    }, {
      title: "Destinations列表",
      key: "list_destinations"
    }, {
      title: "QuerySnippets列表",
      key: "list_query_snippets"
    }
  ]
}]

export const permissionProdTree = [{
  title: "Dashboard",
  key: "Dashboard",
  children: [
    {
      title: "Dashboard列表",
      key: "list_dashboards"
    }, {
      title: "创建Dashboard",
      key: "create_dashboard",
      disabled: true
    }, {
      title: "编辑Dashboard",
      key: "edit_dashboard",
      disabled: true
    },
  ]
},
{
  title: "Query",
  key: "Query",
  children: [
    {
      title: "Query列表",
      key: "list_query"
    }, {
      title: "创建Query",
      key: "create_query",
      disabled: true
    }, {
      title: "编辑Query",
      key: "edit_query",
      disabled: true
    }, {
      title: "仅查看Query结果集",
      key: "view_query"
    }, {
      title: "仅查看Query Schema",
      key: "view_source"
    }, {
      title: "运行Query",
      key: "execute_query"
    }, {
      title: "定时刷新Query",
      key: "schedule_query",
      disabled: true
    },
  ]
}, {
  title: "Alert",
  key: "Alert",
  children: [
    {
      title: "Alert列表",
      key: "list_alerts",
      disabled: true
    },
    {
      title: "创建Alert",
      key: "create_alerts",
      disabled: true
    },
  ]
}, {
  title: "Setting",
  key: "Setting",
  children: [
    {
      title: "DataSource列表",
      key: "list_data_sources",
      disabled: true
    }, {
      title: "User列表",
      key: "list_users",
      disabled: true
    }, {
      title: "Group列表",
      key: "list_groups",
      disabled: true
    }, {
      title: "Destinations列表",
      key: "list_destinations",
      disabled: true
    }, {
      title: "QuerySnippets列表",
      key: "list_query_snippets",
      disabled: true
    }
  ]
}]




