import React, { useState, useImperativeHandle, useEffect } from "react";
import Modal from "antd/lib/modal";
import Checkbox from "antd/lib/checkbox";
import Radio from "antd/lib/radio";
import Skeleton from "antd/lib/skeleton";
import notification from "@/services/notification";
import Group from "@/services/group";
import "./index.less";
import Empty from "antd/lib/empty";
import Divider from "antd/lib/divider";
import DataSource from "@/services/data-source";
import { cloneDeep, isEmpty, pick, remove } from "lodash";

export default function EditPermission({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [groupId, setGroupID] = useState();

  const [loading, setLoading] = useState(false);

  const [dataSourceList, setDataSourceList] = useState([]);

  const [getLoading, setGetLoading] = useState(false);

  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [currentList, setCurrentList] = useState([]);

  const [prevList, setPrevList] = useState([]);

  const [title, setTitle] = useState();

  const [dashboardList, setDashboardList] = useState([]);

  useEffect(() => {
    if (currentList.length) {
      setIndeterminate(currentList.some(item => item.checked) && currentList.some(item => !item.checked));
      setCheckAll(currentList.every(item => item.checked));
    }
  }, [currentList]);

  const show = async item => {
    setVisible(true);
    setTitle(item.name);
    setDashboardList(item.dashboards.map(item => item.id) || []);
    let dataList = cloneDeep(dataSourceList);
    setGroupID(item.id);
    if (isEmpty(dataSourceList)) {
      setGetLoading(true);
      const data = await DataSource.query();
      dataList = data.map(item => ({
        id: item.id,
        name: `${item.name}(${item.type})`,
        view_only: true,
        checked: false,
      }));
      setDataSourceList(dataList);
    }

    const data = await Group.dataSources({
      id: item.id,
    });
    setPrevList(data.map(item => pick(item, "id", "view_only")));

    setCurrentList(
      dataList
        .map(item => {
          const findItem = data.find(i => i.id === item.id);
          return {
            id: item.id,
            name: item.name,
            view_only: findItem ? findItem.view_only : item.view_only,
            checked: !!findItem,
          };
        })
        .sort(a => (a.checked ? -1 : 1))
    );

    setGetLoading(false);
  };

  const hide = () => {
    setVisible(false);
    setGroupID(null);
    setCheckAll(false);
    setIndeterminate(false);
    setPrevList([]);
    setCurrentList([]);
    setGetLoading(false);
    setLoading(false);
    setTitle(undefined);
    setDashboardList([]);
  };

  const onSuccess = async () => {
    setLoading(true);
    notification.config({
      placement: "topRight",
    });

    // 处理保存逻辑
    const checkList = currentList.filter(item => item.checked);
    const prevIdList = prevList.map(item => item.id);
    const checkIdList = checkList.map(item => item.id);
    const removeList = prevIdList
      .filter(item => !checkIdList.includes(item))
      .map(dataSourceId => Group.removeDataSource({ id: groupId, dataSourceId }));

    const addList = checkIdList
      .filter(item => !prevIdList.includes(item))
      .map(data_source_id => Group.addDataSource({ id: groupId }, { data_source_id }));

    await Promise.all(removeList.concat(addList));

    const updateList = checkList
      .filter(item => {
        const findItem = prevList.find(i => i.id === item.id);
        if (findItem) return findItem.view_only !== item.view_only;
        return !item.view_only;
      })
      .map(item => Group.updateDataSource({ id: groupId, dataSourceId: item.id }, { view_only: item.view_only }));

    await Promise.all(updateList);

    if (removeList.length > 0) {
      const data = (await Group.getAllDashboard(groupId)).map(item => item.id);
      const dashboardResult = cloneDeep(dashboardList);
      remove(dashboardResult, item => !data.includes(item));
      if (dashboardList.length !== dashboardResult) {
        await Group.saveDashboard({
          group_id: groupId,
          dashboard_ids: dashboardResult,
        });
      }
    }

    setLoading(false);
    notification.success("修改成功");
    hide();
    onSuccessChange();
  };

  const onCheckAllChange = e => {
    setCurrentList(prev => {
      return e.target.checked
        ? prev.map(item => ({ ...item, checked: true }))
        : prev.map(item => ({ ...item, checked: false }));
    });
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  const handleCheckChange = (v, id) => {
    const temp = cloneDeep(currentList);
    const item = temp.find(i => i.id === id);
    item.checked = v;
    setCurrentList(temp);
  };

  const handleRadioChange = (v, id) => {
    const temp = cloneDeep(currentList);
    const item = temp.find(i => i.id === id);
    item.view_only = v;
    setCurrentList(temp);
  };

  return (
    <Modal
      visible={visible}
      title={`数据源 | ${title}`}
      onCancel={hide}
      okButtonProps={{ loading }}
      className="dataSourceEditModal"
      onOk={onSuccess}
      destroyOnClose
      width={"600px"}>
      {getLoading ? (
        <Skeleton active />
      ) : dataSourceList.length > 0 ? (
        <div className="dataSourceEditModal-content">
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            选择全部
          </Checkbox>
          <Divider />
          <div className="ant-checkbox-group">
            {currentList.map(item => {
              return (
                <div className="check-item" key={item.id}>
                  <Checkbox checked={item.checked} onChange={e => handleCheckChange(e.target.checked, item.id)}>
                    {item.name}
                  </Checkbox>
                  <Radio.Group
                    value={item.view_only}
                    onChange={e => handleRadioChange(e.target.value, item.id)}
                    options={[
                      { value: true, label: "View Only" },
                      { value: false, label: "Full Access" },
                    ]}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Empty></Empty>
      )}
    </Modal>
  );
}
