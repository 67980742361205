import { isNil, isEmpty } from "lodash";
import { useMemo } from "react";
import { currentUser } from "@/services/auth";
import { policy } from "@/services/policy";

export default function useQueryFlags(query, dataSource = null) {
  dataSource = dataSource || { view_only: true };

  return useMemo(
    () => ({
      // state flags
      isNew: isNil(query.id),
      isDraft: query.is_draft,
      isArchived: query.is_archived,

      // permissions flags
      canCreate: currentUser.hasPermission("create_query"),
      canView: currentUser.hasPermission("view_query"),
      canEdit: currentUser.hasPermission("edit_query"), //&& policy.canEdit(query),
      canViewSource: currentUser.hasPermission("view_source"),
      canExecuteQuery: currentUser.hasPermission("execute_query"),
      canExecute:
        !isEmpty(query.query) &&
        policy.canRun(query) &&
        (query.is_safe || (currentUser.hasPermission("execute_query") && !dataSource.view_only)),
      canFork: currentUser.hasPermission("edit_query") && !dataSource.view_only,
      canSchedule: currentUser.hasPermission("schedule_query"),
    }),
    [query, dataSource.view_only]
  );
}
