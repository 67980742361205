import React, { useEffect, useState } from "react";
import Icon from "@/components/Icon";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import Button from "antd/lib/button";
import { Dashboard } from "@/services/dashboard";

export default ({ dashboardConfiguration }) => {
  const [menuList, setMenuList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // 获取menu
    Dashboard.getDashboardMenu(dashboardConfiguration.dashboard.id)
      .then(res => {
        setMenuList(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dashboardConfiguration.dashboard.id]);

  const handleClickMenu = ({ key }) => {
    const dom = document.getElementById(`dashboard_${key}`);
    if (dom) {
      dom.scrollIntoView();
    }
  };

  return !loading && menuList.length > 0 ? (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      overlayClassName="dashboard-menu-dropdown"
      overlay={
        <Menu onClick={handleClickMenu}>
          {menuList.map(item => {
            return <Menu.Item key={item.id}>{item.text}</Menu.Item>;
          })}
        </Menu>
      }>
      <Button className="icon-button m-l-5">
        <Icon type="icon-menu" />
      </Button>
    </Dropdown>
  ) : null;
};
