import React, { useState, useImperativeHandle } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import notification from "@/services/notification";
import { Dashboard } from "@/services/dashboard";

export default function QueryCopyModal({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const show = item => {
    // 获取基本信息
    setVisible(true);
    form.setFieldsValue({
      id: item.id,
      name: `Copy of (#${item.id}) ${item.name}`,
    });
  };

  const hide = () => {
    setVisible(false);
    form.resetFields();
    setLoading(false);
  };

  const onSuccess = async () => {
    notification.config({ placement: "topRight" });
    const formData = await form.validateFields();
    setLoading(true);
    try {
      await Dashboard.copy(formData);
      notification.success("复制成功");
      hide();
      onSuccessChange();
    } catch {
      notification.error("复制失败 请稍后重试!");
    } finally {
      setLoading(false);
    }
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Modal
      visible={visible}
      title="复制 Dashboard"
      onCancel={hide}
      maskClosable={false}
      okButtonProps={{ loading }}
      onOk={onSuccess}>
      <Form form={form}>
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Copy Dashboard Name"
          rules={[{ required: true, message: "请输入复制后Dashboard名字" }]}>
          <Input placeholder="输入Dashboard Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
