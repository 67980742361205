import React from 'react'

function ErrorTips(props) {
  return (
    <div
      style={{
        padding: "20px 40px",
        fontSize: "16px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <span style={{ color: "red", marginRight: "10px" }} role="img" aria-label="">
        ⚠️
      </span>
      {props?.messsage || 'It seems like we encountered an error. Try refreshing this page or contact your administrator.'}
    </div>
  );
}
export default ErrorTips;