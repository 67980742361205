import React, { useState, useImperativeHandle, useEffect } from "react";
import Modal from "antd/lib/modal";
import Checkbox from "antd/lib/checkbox";
import Skeleton from "antd/lib/skeleton";
import notification from "@/services/notification";
import Group from "@/services/group";
import User from "@/services/user";
import "./index.less";
import Empty from "antd/lib/empty";
import Divider from "antd/lib/divider";
import { isEmpty, flatMap, sortBy, cloneDeep, remove } from "lodash";
import Search from "antd/lib/input/Search";

export default function EditPermission({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [value, setValue] = useState([]);

  const [prevValue, setPrecValue] = useState([]);

  const [groupId, setGroupID] = useState();

  const [loading, setLoading] = useState(false);

  const [getLoading, setGetLoading] = useState(false);

  const [userList, setUserList] = useState([]);

  const [searchKey, setSearchKey] = useState();

  const [userOption, setUserOption] = useState([]);

  const [title, setTitle] = useState();

  useEffect(() => {
    if (searchKey) {
      setUserOption(userList.filter(item => item.label.toLowerCase().includes(searchKey.toLowerCase())));
    } else {
      setUserOption(userList);
    }
  }, [searchKey, userList]);

  const onChange = v => {
    let temp = cloneDeep(value);
    const valueList = userOption.map(item => item.value);
    remove(temp, item => valueList.includes(item));
    temp = temp.concat(v);
    setValue(temp);
  };

  const loadAllUserData = async () => {
    const page_size = 200;
    setGetLoading(true);
    let resultData = [];
    const data = await User.query({ page_size });
    resultData = data.results;
    if (data.count > page_size) {
      const dataList = await Promise.all(
        new Array(Math.ceil(data.count / page_size) - 1)
          .fill(undefined)
          .map((item, index) => User.query({ page: index + 2, page_size }))
      );
      resultData = flatMap(data.results.concat(dataList.map(item => item.results)));
    }
    setGetLoading(false);

    return resultData.map(item => ({
      value: item.id,
      label: item.email,
    }));
  };

  const show = async item => {
    setGroupID(item.id);
    setTitle(item.name);
    setVisible(true);
    const v = item.members.map(item => item.id) || [];
    setPrecValue(v);
    setValue(v);
    let userData = cloneDeep(userList);
    if (isEmpty(userList)) {
      userData = await loadAllUserData();
    }
    const checkedData = remove(userData, item => v.includes(item.value));
    userData = checkedData.concat(userData);
    setUserList(userData);
  };

  const hide = () => {
    setVisible(false);
    setGroupID(null);
    setTitle(undefined);
    setValue([]);
    setPrecValue([]);
    setSearchKey(undefined);
    setGetLoading(false);
  };

  const onSuccess = async () => {
    setLoading(true);
    notification.config({ placement: "topRight" });
    const removeList = prevValue
      .filter(item => !value.includes(item))
      .map(userId => Group.removeMember({ id: groupId, userId }));

    const addList = value
      .filter(item => !prevValue.includes(item))
      .map(user_id => Group.addMember({ id: groupId }, { user_id }));

    await Promise.all(removeList.concat(addList));

    notification.success("修改成功");
    hide();
    onSuccessChange();
    setLoading(false);
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Modal
      visible={visible}
      title={`用户 | ${title} Member`}
      onCancel={hide}
      okButtonProps={{ loading }}
      className="memberEditModal"
      onOk={onSuccess}
      destroyOnClose>
      {getLoading ? (
        <Skeleton active />
      ) : userList.length > 0 ? (
        <div className="memberEditModal-content">
          <Search value={searchKey} onChange={e => setSearchKey(e.target.value)} allowClear placeholder="Search User" />
          <Divider />
          <Checkbox.Group value={value} onChange={onChange} options={userOption} />
        </div>
      ) : (
        <Empty></Empty>
      )}
    </Modal>
  );
}
