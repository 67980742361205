import React, { useState, useImperativeHandle } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import notification from "@/services/notification";
import Group from "@/services/group";

export default function EditPermission({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [itemDetail, setItemDetail] = useState();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const show = item => {
    // 获取基本信息
    setItemDetail(item);
    setVisible(true);
    form.setFieldsValue({
      name: item.name,
    });
  };

  const hide = () => {
    setVisible(false);
    setItemDetail(null);
    form.resetFields();
  };

  const onSuccess = async () => {
    const formData = await form.validateFields();
    setLoading(true);

    notification.config({ placement: "topRight" });
    Group.save({
      ...(itemDetail || {}),
      name: formData.name,
    })
      .then(() => {
        notification.success("修改成功");
        hide();
        onSuccessChange();
        setLoading(false);
      })
      .catch(e => {
        notification.error("修改失败，稍后再试");
        setLoading(false);
      });
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Modal
      visible={visible}
      title={`Group Name | ${itemDetail?.name}`}
      onCancel={hide}
      okButtonProps={{ loading }}
      className="nameEditModal"
      onOk={onSuccess}
      destroyOnClose>
      <Form form={form}>
        <Form.Item name="name" required rules={[{ required: true, message: "请输入新的Group Name" }]}>
          <Input placeholder="请输入New Group Name"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
}
