import React from "react";

import Button from "antd/lib/button";
import routeWithUserSession from "@/components/ApplicationArea/routeWithUserSession";
import Link from "@/components/Link";
import { useTitle } from "@/lib/hooks/useTitle"
import { wrap as itemsList, ControllerType } from "@/components/items-list/ItemsList";
import { ResourceItemsSource } from "@/components/items-list/classes/ItemsSource";
import { StateStorage } from "@/components/items-list/classes/StateStorage";

import LoadingState from "@/components/items-list/components/LoadingState";
import EmptyState from "@/components/items-list/components/EmptyState";
import ItemsTable, { Columns } from "@/components/items-list/components/ItemsTable";

import CreateGroupDialog from "@/components/groups/CreateGroupDialog";
import DeleteGroupButton from "@/components/groups/DeleteGroupButton";
import wrapSettingsTab from "@/components/SettingsWrapper";

import EditName from "@/components/groups/EditName";
import EditPermission from "@/components/groups/EditPermissions";
import EditDashboard from "@/components/groups/EditDashboard";
import EditDataSource from "@/components/groups/EditDataSource";
import EditMember from "@/components/groups/EditMember";
import GroupDetail from "@/components/groups/GroupDetail";

import Group from "@/services/group";
import { currentUser } from "@/services/auth";
import routes from "@/services/routes";
import Search from "antd/lib/input/Search";
import Icon from "@/components/Icon";

import "./GroupsList.less";

class GroupsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [], searchKey: "" };
  }

  static propTypes = {
    controller: ControllerType.isRequired,
  };

  modaleRef = React.createRef();
  dashboardRef = React.createRef();
  dataSourceRef = React.createRef();
  membersRef = React.createRef();
  drawerRef = React.createRef();
  nameRef = React.createRef();

  updateSearch = v => {
    const { controller } = this.props;
    const temp = (controller.allItems || [])
      .filter(item => item.name.toLowerCase().includes(v?.toLowerCase()))
      .sort((a, b) => b.id - a.id);
    this.setState({
      dataSource: temp,
      searchKey: v,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.controller.pageItems !== prevProps.controller.pageItems) {
      this.updateSearch(this.state.searchKey);
    }
  }

  handleName = item => {
    this.nameRef.current.show(item);
  };

  handlePermission = item => {
    this.modaleRef.current.show(item);
  };

  handleDashboard = item => {
    this.dashboardRef.current.show(item);
  };

  handleDataSource = item => {
    this.dataSourceRef.current.show(item);
  };

  handleMembers = item => {
    this.membersRef.current.show(item);
  };

  handleDetail = item => {
    this.drawerRef.current.show(item);
  };

  listColumns = [
    Columns.custom(
      (text, group) => (
        <div className="x-group-name">
          <span>{group.name}</span>
          <Icon style={{ marginLeft: "5px" }} type="icon-edit" onClick={() => this.handleName(group)}></Icon>
          <span className="label label-default m-l-10">{group.tag}</span>
        </div>
      ),
      {
        field: "name",
        title: "Name",
        width: null,
      }
    ),

    Columns.custom(
      (text, group) => (
        <Button.Group>
          {currentUser.isAdmin && (
            <Link.Button onClick={() => this.handlePermission(group)}>
              Permission{`(${group?.permissions?.length || 0})`}
            </Link.Button>
          )}
        </Button.Group>
      ),
      {
        title: "Operation",
        width: "1%",
        className: "text-nowrap",
      }
    ),

    Columns.custom(
      (text, group) => (
        <Button.Group>
          {currentUser.isAdmin && (
            <Link.Button onClick={() => this.handleDashboard(group)}>
              Dashboard{`(${group?.dashboards?.length || 0})`}
            </Link.Button>
          )}
        </Button.Group>
      ),
      {
        width: "1%",
        className: "text-nowrap",
      }
    ),

    Columns.custom(
      (text, group) => (
        <Button.Group>
          {currentUser.isAdmin && (
            <Link.Button onClick={() => this.handleDataSource(group)}>
              Data Sources{`(${group?.data_sources?.length || 0})`}
            </Link.Button>
          )}
        </Button.Group>
      ),
      {
        width: "1%",
        className: "text-nowrap",
      }
    ),

    Columns.custom(
      (text, group) => (
        <Button.Group>
          <Link.Button onClick={() => this.handleMembers(group)}>
            Members{`(${group?.members?.length || 0})`}
          </Link.Button>
        </Button.Group>
      ),
      {
        width: "1%",
        className: "text-nowrap",
      }
    ),

    Columns.custom(
      (text, group) => (
        <Button className="w-100" onClick={() => this.handleDetail(group)}>
          Detail
        </Button>
      ),
      {
        width: "1%",
        className: "text-nowrap p-l-0",
      }
    ),
    Columns.custom(
      (text, group) => {
        const canRemove = group.type !== "builtin";
        return (
          <DeleteGroupButton
            className="w-100"
            disabled={!canRemove}
            group={group}
            title={canRemove ? null : "Cannot delete built-in group"}
            onClick={() => this.onGroupDeleted()}>
            Delete
          </DeleteGroupButton>
        );
      },
      {
        width: "1%",
        className: "text-nowrap p-l-0",
        isAvailable: () => currentUser.isAdmin,
      }
    ),
  ];

  createGroup = () => {
    CreateGroupDialog.showModal().onClose(group => Group.create(group).then(() => this.props.controller.update()));
  };

  onGroupDeleted = () => {
    this.props.controller.updatePagination({ page: 1 });
    this.props.controller.update();
  };

  render() {
    const { controller } = this.props;

    return (
      <div data-test="GroupList">
        {currentUser.isAdmin && (
          <div className="m-b-15">
            <Button type="primary" onClick={this.createGroup}>
              <i className="fa fa-plus m-r-5" aria-hidden="true" />
              New Group
            </Button>
          </div>
        )}
        {!controller.isLoaded && <LoadingState className="" />}
        {controller.isLoaded && controller.isEmpty && <EmptyState className="" />}
        {controller.isLoaded && !controller.isEmpty && (
          <div className="table-responsive">
            <div style={{ marginBottom: "5px" }}>
              <Search
                value={this.state.searchKey}
                onChange={e => this.updateSearch(e.target.value)}
                placeholder="Search Group"
              />
            </div>
            <ItemsTable
              items={this.state.dataSource}
              columns={this.listColumns}
              context={this.actions}
              orderByField={controller.orderByField}
              orderByReverse={controller.orderByReverse}
              toggleSorting={controller.toggleSorting}
            />
          </div>
        )}
        <EditName modalRef={this.nameRef} onSuccessChange={controller.update} />
        <EditPermission modalRef={this.modaleRef} onSuccessChange={controller.update} />
        <EditDashboard modalRef={this.dashboardRef} onSuccessChange={controller.update} />
        <EditDataSource modalRef={this.dataSourceRef} onSuccessChange={controller.update} />
        <EditMember modalRef={this.membersRef} onSuccessChange={controller.update} />
        <GroupDetail drawerRef={this.drawerRef} />
      </div>
    );
  }
}

const GroupsListPage = wrapSettingsTab(
  "Groups.List",
  {
    permission: "list_groups",
    title: "Groups",
    path: "groups",
    order: 3,
  },
  itemsList(
    GroupsList,
    () =>
      new ResourceItemsSource({
        isPlainList: true,
        getRequest() {
          return {};
        },
        getResource() {
          return Group.query.bind(Group);
        },
      }),
    () => new StateStorage({ orderByField: "name", itemsPerPage: 100 })
  )
);

routes.register(
  "Groups.List",
  routeWithUserSession({
    path: "/groups",
    title: useTitle("Groups"),
    render: pageProps => <GroupsListPage {...pageProps} currentPage="groups" />,
  })
);
