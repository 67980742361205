export function useTitle(title: string) {
  const titleMapping: any = {
    local: "本地",
    dev: "Dev",
    test: "Test",
    prod: "",
    bigone: "内部",
    datalava: "",
  };
  const env: string = process.env.REDASH_ENV || "";
  return ["prod", "datalava"].includes(env) ? title : `【${titleMapping[env]}】${title}`;
}
