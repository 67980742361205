import React, { useState, useImperativeHandle, useEffect } from "react";
import Modal from "antd/lib/modal";
import Checkbox from "antd/lib/checkbox";
import Skeleton from "antd/lib/skeleton";
import notification from "@/services/notification";
import Group from "@/services/group";
import "./index.less";
import Empty from "antd/lib/empty";
import Divider from "antd/lib/divider";
import { remove } from "lodash";

export default function EditPermission({ modalRef, onSuccessChange }) {
  const [visible, setVisible] = useState(false);

  const [value, setValue] = useState([]);

  const [groupId, setGroupID] = useState();

  const [group, setGroup] = useState({});

  const [loading, setLoading] = useState(false);

  const [dashboardList, setDashboardList] = useState([]);

  const [getLoading, setGetLoading] = useState(false);

  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [title, setTitle] = useState();

  useEffect(() => {
    if (dashboardList.length) {
      setIndeterminate(!!value.length && value.length < dashboardList.length);
      setCheckAll(value.length === dashboardList.length);
    }
  }, [value, dashboardList]);

  const onChange = v => {
    setValue(v);
  };

  const show = async item => {
    setGroup(item);
    setGetLoading(true);
    setGroupID(item.id);
    setTitle(item.name);
    setVisible(true);
    const v = item.dashboards.map(item => item.id) || [];
    setValue(v);
    let data = await Group.getAllDashboard(item.id);
    const checkedData = remove(data, item => v.includes(item.id));
    data = checkedData.concat(data);
    setDashboardList(
      data.map(item => ({
        value: item.id,
        label: item.name,
      }))
    );
    setGetLoading(false);
  };

  const hide = () => {
    setVisible(false);
    setGroupID(null);
    setValue([]);
    setTitle(undefined);
    setCheckAll(false);
    setIndeterminate(false);
    setGroup({})
  };

  const onSuccess = async () => {
    setLoading(true);
    notification.config({ placement: "topRight" });
    Group.saveDashboard({
      group_id: groupId,
      dashboard_ids: value,
    })
      .then(() => {
        notification.success("修改成功");
        hide();
        onSuccessChange();
        setLoading(false);
      })
      .catch(e => {
        notification.error("修改失败，稍后再试");
        setLoading(false);
      });
  };

  const onCheckAllChange = e => {
    setValue(e.target.checked ? dashboardList.map(item => item.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  if (modalRef) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useImperativeHandle(modalRef, () => {
      return {
        show,
        hide,
      };
    });
  }

  return (
    <Modal
      visible={visible}
      title={`数据权限 | ${title}`}
      onCancel={hide}
      okButtonProps={{ loading }}
      className="dashboardEditModal"
      onOk={onSuccess}
      footer={group.type === 'builtin' ? <></> : undefined}
      destroyOnClose>
      {getLoading ? (
        <Skeleton active />
      ) : dashboardList.length > 0 ? (
        <div className="dashboardEditModal-content">
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} disabled={group.type === "builtin"}>
            选择全部
          </Checkbox>
          <Divider />
          <Checkbox.Group disabled={group.type === "builtin"} value={value} onChange={onChange} options={dashboardList} />
        </div>
      ) : (
        <Empty></Empty>
      )}
    </Modal>
  );
}
